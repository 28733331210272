/* Index css */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

div {
  box-sizing: border-box;
}

p, li {
  line-height: 1.7;
  color: #383838;
}

html {
  scroll-behavior: smooth;
}

a, a:visited, a:hover, a:active {
    text-decoration: none;
    color: '#309c8a';
    text-decoration-color: '#309c8a';
}


/* ::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  } */

/* General */

.App {
  height: 100vh;
  overflow-y: scroll;
}

.Dept::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

/* Page */
.particle-background {
  width: calc(100% - 12px);
  height: calc(100vh - 76.16px);
  position: absolute;
  background-color: transparent;
  z-index:0;
  overflow-y: hidden;
}

.particle-container {
  width: 100%;
  height: 100%;
  overflow-y: hidden;
}

/* Links */

.internal-indicator-contain {
  margin-top: 10px;
  display: flex;
  justify-content: center;
}

.internal-indicator {
  background-color: #00426a80;
  color: white;
  border-radius: 5px;
  width: 80px;
  line-height: 22px;
  font-size: 1rem;
}

.link-button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 150px;
  padding: 10px 0px;
  text-transform: none;
}

/* Timeline */
/* Container around content */
.timeline-container {
  padding: 1px 3%;
  position: relative;
  background-color: inherit;
  border-left: 4px solid #309c8a;
  left: 20px;
}

/* The circles on the timeline */
.timeline-container::after {
  content: '';
  position: absolute;
  width: 30px;
  height: 30px;
  left: -21px;
  background-color: white;
  border: 4px solid #309c8a;
  top: 0px;
  border-radius: 50%;
  z-index: 1;
}
